import * as React from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import useImportScript from '../../../utils/useImportScript'
import config from '../../../config';
const {
    managementUrl
} = config;

const ServicesPage = (props) => {
    useEffect(() => {
        document.title = `${props.title} | ${props.page}`;  
    }, [props]);
  useImportScript('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    return (
          <Grid container className="page-container">
            <div class="row gx-4 gx-lg-5 mt-5">
                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-body">
                                <h2 class="card-title">Choose colors from templates</h2>
                                <p class="card-text">We have used a color scheme generator to ensure your colors will pop and content will be clear for all to see.</p>
                            </div>
                            <div class="card-footer"><a class="btn btn-primary btn-sm" href={managementUrl}>More Info</a></div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-body">
                                <h2 class="card-title">Double Opt In</h2>
                                <p class="card-text">We send out a confirmation email to your users to ensure we have a double opt in so your emails will not be blocked. This also ensure your campaigns get less bounces.</p> 
                            </div>
                            <div class="card-footer"><a class="btn btn-primary btn-sm" href={managementUrl}>Sign Up Today</a></div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-body">
                                <h2 class="card-title">Registration Form</h2>
                                <p class="card-text">Once you are registered you will get find instructions in our administration portal on adding our registration form to your html or React website.</p>
                            </div>
                            <div class="card-footer"><a class="btn btn-primary btn-sm" href={managementUrl}>Start now</a></div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-5">
                        <div class="card h-100">
                            <div class="card-body">
                                <h2 class="card-title">Advance Scheduling</h2>
                                <p class="card-text">You can schedule a run from 5 days to a 24 hours in advance.</p>
                            </div>
                        <div class="card-footer"><a class="btn btn-primary btn-sm" href={managementUrl}>More Info</a></div>
                        </div>
                    </div>
                </div>
            </Grid>
    );
}

export default ServicesPage;