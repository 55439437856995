const featureFlags = {
    local: {
        userPortal: true,
        registrationAvailable: true,
        highContrastAvailable: false,
    },
    dev: {
        userPortal: true,
        registrationAvailable: false,
        highContrastAvailable: false,
    },
    production: {
        userPortal: false,
        registrationAvailable: false,
        highContrastAvailable: false,
    }
}

const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'dev';

export default featureFlags[env];
