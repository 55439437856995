import * as React from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import useImportScript from '../../../utils/useImportScript'
import '../../../App.css';
import ContactUsFamilyImg from '../../../assets/AdobeStock_203649182.jpeg';

const ContactPage = (props) => {
    useEffect(() => {
        document.title = `${props.title} | ${props.page}`;  
    }, [props]);
    useImportScript('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    return (
       <Grid container className="page-container px-4 px-lg-5">
        <Grid item className='gx-4 gx-lg-5 align-items-center my-5'>
          <h2>Keep in contact with close friends and family.</h2> 
        </Grid><Grid item xs={12}>
          <div class="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src={ContactUsFamilyImg} alt="..." /></div>
          <div class="col-lg-5">
            <h1 class="font-weight-light">NewslettersForHire.com</h1>
            <p> 5543 Edmondson Pike</p>
            <p> Suite #1</p>
            <p> Nashville, TN 37211</p>
            <a class="btn btn-primary" href="/services">Get Started!</a>
          </div>
        </Grid>
       </Grid>
    );
}

export default ContactPage;