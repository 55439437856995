import featureFlags from './feature-flags.js';
const config = {
    local: {
        baseUrl: "http://localhost:6001",
        tokensApi: "http://localhost:3000/dev",
        registationApi: "http://localhost:3000/dev",
        managementUrl: "http://localhost:6002",
    },
    dev: {
        baseUrl: "https://dev.newslettersforhire.com",
        tokensApi: "",
        registationApi: "",
        managementUrl: "https://devadmin.newslettersforhire.com",
    },
    production: {
        baseUrl: "https://newslettersforhire.com",
        tokensApi: "",
        registationApi: "",
        managementUrl: "https://admin.newslettersforhire.com",
    },
    
}

const env = process.env.REACT_APP_BUILD_ENV ? `${process.env.REACT_APP_BUILD_ENV}` : 'local';
const currentConfig = {
  ...config[env],
  featureFlags
}
export default currentConfig;