import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MenuAppBar from './components/navbar';
import AppFooter from './components/footer';
import HomePage from './components/pages/homePage';
import AboutPage from './components/pages/aboutPage';
import ServicesPage from './components/pages/servicesPage';
import ContactPage from './components/pages/contactPage';

const pageTitle = "NewslettersForHire.com";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MenuAppBar />
      <Routes>
        <Route path="/" element={<HomePage title={pageTitle} page="Home" />} />
        <Route path="/about/" element={<AboutPage title={pageTitle} page="About" />} />
        <Route path="/services/" element={<ServicesPage title={pageTitle} page="Services" />} />
        <Route path="/contact/" element={<ContactPage title={pageTitle} page="Contact" />} />
      </Routes>
        <AppFooter />
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
