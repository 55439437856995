import '../../../App.css';
import useImportScript from '../../../utils/useImportScript';
import { useEffect } from 'react';
import NewslettersImage from '../../../assets/AdobeStock_259988723.jpeg';

const HomePage = (props) => {
    useEffect(() => {
        document.title = `${props.title} | ${props.page}`;
    }, [props]);    
  useImportScript('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
  return (
    <div className="App">
      {/* <!-- Page Content--> */}
        <div class="page-container px-4 px-lg-5">
            {/* <!-- Heading Row--> */}
            <div class="row gx-4 gx-lg-5 align-items-center my-5">
                {/* <div class="col-lg-7"><img class="img-fluid rounded mb-4 mb-lg-0" src="https://dummyimage.com/900x400/dee2e6/6c757d.jpg" alt="..." /></div> */}
                  <div class="col-lg-7"><img src={ NewslettersImage } className="img-fluid rounded mb-4 mb-lg-0" alt="..." /></div>
                <div class="col-lg-5">
                    <h1 class="font-weight-light">Newsletters without the subscriptions</h1>
                    <p>Needs to send quick newsletters to small groups without the hassle of designing and hosting the assets? Low pricing and quick adjusting of templates makes getting campaigns out the door easy and affordable.</p>
                    <a class="btn btn-primary" href="/services">Get Started!</a>
                </div>
            </div>
            {/* <!-- Call to Action--> */}
            <div class="card text-white bg-secondary my-5 py-4 text-center">
                <div class="card-body"><p class="text-white m-0">Add a form or component to your website and users can start subscribing today!</p></div>
            </div>
            {/* <!-- Content Row--> */}
            <div class="row gx-4 gx-lg-5">
                <div class="col-md-4 mb-5">
                    <div class="card h-100">
                        <div class="card-body">
                            <h2 class="card-title">Low Pricing</h2>
                            <p class="card-text">We provide the templates you can use and send from our domain so you can save money while sending out quality newsletters.</p>
                        </div>
                        <div class="card-footer"><a class="btn btn-primary btn-sm" href="/services">More Info</a></div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card h-100">
                        <div class="card-body">
                            <h2 class="card-title">Small Email Lists</h2>
                            <p class="card-text">Sending to family and friends about life events and updates can be easy and quick with our system. Send them a link via email and your registered list can start getting updates from you whenever you have them.</p>
                        </div>
                        <div class="card-footer"><a class="btn btn-primary btn-sm" href="/about">About Us</a></div>
                    </div>
                </div>
                <div class="col-md-4 mb-5">
                    <div class="card h-100">
                        <div class="card-body">
                            <h2 class="card-title">Registration Form</h2>
                            <p class="card-text">Once you are registered you will get find instructions in our administration portal on adding our registration form to your html or React website.</p>
                        </div>
                        <div class="card-footer"><a class="btn btn-primary btn-sm" href="/services">Services</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default HomePage;