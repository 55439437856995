import * as React from 'react';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import useImportScript from '../../../utils/useImportScript'
import '../../../App.css';
import NewsletterReceivedImg from '../../../assets/AdobeStock_416930594.jpeg';

const AboutPage = (props) => {
    useEffect(() => {
        document.title = `${props.title} | ${props.page}`;  
    }, [props]);
    useImportScript('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    return (
      <Grid container disableGutters={true} className="page-container" >
        <Grid item xs={12} md={12} sx={{textAlign:'center', marginTop:'3em'}}>
          <h2>About NewsletterForHire.com</h2> 
        </Grid>
        <Grid item xs={6} sx={{ float:'left', clear:'left', padding:'1em' }}>
          <p>You are someone who loves connection with your community. You have something to say, but don’t want to commit to having yet another expensive yearly subscription. You want to connect on your terms, and connect on demand. </p>
        </Grid>
        <Grid item xs={6} sx={{ float:'right', clear:'right', padding:'1em' }}>
          <p>Create one of a kind newsletters on your schedule. Sign up to create on a one to one basis, or to save and schedule months in advance. Whatever your schedule and needs, we’ve got you covered. </p>
        </Grid>
        {/* <!-- Call to Action--> */}
        <Grid item xs={12} md={12} className="card text-white bg-secondary my-5 py-4 text-center">
            <Grid item className="card-body"><p className="text-white m-0">We use less flash to save you more!</p></Grid>
        </Grid>
        <Grid item xs={12} md={12} sx={{ padding:'1em' }}>
          <p>Newsletters for Hire gives the opportunity for anyone to create and send their own templated newsletters. Whether you’re a business owner just starting out, an established business looking to make a change, or just someone who wants an easier and more effective way to send emails to a group of people in your life, this is the place for you. </p>
        </Grid>
        <Grid>
          <div class="col-lg-12"><img src={ NewsletterReceivedImg } className="img-fluid rounded mb-4 mb-lg-0" alt="..." /></div>
        </Grid>
       </Grid>
    );
}

export default AboutPage;